<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <RegionForm></RegionForm>
          </div>
          <div class="vx-card p-6 mb-6">
              <SeoInfo></SeoInfo>
            </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
        </div>
       </div>
            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
    </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import RegionForm from './common/RegionForm';
import AdditionInfo from './common/AdditionInfo';
import SeoInfo from './common/SeoInfo';
import {HOMESTAY_REGION} from '../../../constant/entity-identifier'
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    RegionForm,
    AdditionInfo,
    SeoInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'homestay_region/getFormObj'
    }),
  },
  methods: {
    ...mapActions({
      createHomestayRegion: 'homestay_region/createHomestayRegion',
      clearForm: 'homestay_region/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors'
    }),
    prepareForm(id, type) {
      let formData = new FormData();
      formData.append("entity_id", id);
      formData.append("entity", type);
      formData.append("alt[0]", this.form.alt_image);
      for (let i = 0; i < this.form.images.length; i++) {
          formData.append("images[" + i + "]", this.form.images[i]);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.createHomestayRegion(this.form)
        .then(async response => {
           if(response.status === 201) {
             if(this.form.images.length > 0) {
                await storeImage(this.prepareForm(response.data.data.id, HOMESTAY_REGION));
                this.closeLoading();
             }else{
                this.closeLoading();
             }
            this.$vs.notify({ 
                title: 'Homestay Region Created',
                text: 'Homestay region created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/homestay-regions');
           }
        }).catch(() => this.closeLoading());
    },
  },
  created() {
      this.clearForm();
      this.clearValidationErrors();
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
